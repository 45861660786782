.container {
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
}

.unauth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.grid-container {
  display: grid;
  grid-template-areas:
    "sidebar header"
    "sidebar content"
    "footer footer";
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 160px 1fr;
  height: 100%;
}

.header {
  grid-area: header;
  background-color: #f8f9fa;
  text-align: center;
}

.sidebar {
  grid-area: sidebar;
  background-color: #343a40;
  display: flex;
  padding: 12px 11px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
}

.content {
  grid-area: content;
}

.footer {
  grid-area: footer;
  background-color: #f8f9fa;
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  background: #212529;
}

/* 테블릿 세로 모드 */
@media (max-width: 1200px) and (orientation: portrait) {
  * {
    font-size: 9px;
  }

  .main-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px; /* 1.25rem을 20px로 수정 */
  }

  .left-content {
    width: 100%;
  }

  .right-content {
    padding: 0;
    width: 100%;
  }
  .grid-container {
    grid-template-areas:
      "sidebar header"
      "sidebar content"
      "footer footer";
    grid-template-rows: auto 1fr auto auto;
    grid-template-columns: auto 1fr auto auto;
    height: 100%;
  }
}

/* 테블릿 가로 모드 */
@media (max-width: 1920px) and (orientation: landscape) {
  * {
    font-size: 11px;
  }
  .grid-container {
    grid-template-areas:
      "sidebar header"
      "sidebar content"
      "footer footer";
    grid-template-rows: auto 1fr auto;
    grid-template-columns: auto 1fr auto;
  }

  .sidebar {
    flex-direction: column;
    justify-content: flex-start;
  }
}
